* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  :root {
    --primaryColor:#35a574;
    --darkGreen: #30664e;
    --mainWhite: #fff;
    --offWhite: #f7f7f7;
    --mainBlack: #222;
    --mainGrey: #ececec;
    --darkGrey: #cfcfcf;
    --mainTransition: all 0.3s linear;
    --mainSpacing: 3px;
    --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
    --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
    --white: #292a2b ;
  --red: #e31b23;
  --bodyColor: #292a2b;
  --borderFormEls: #35a574;
  --bgFormEls: #cfcfcf;
  --bgFormElsFocus: #cfcfcf;
  }
  /* globals */
  body {
    padding-top: 66px;
    color: var(--mainBlack);
    background: var(--mainWhite);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    line-height: 1.4;
  }
  h1 {
    font-size: 3em;
    line-height: 1;
    margin-bottom: 0.5em;
  }
  h2 {
    font-size: 2em;
    margin-bottom: 0.75em;
  }
  h3 {
    font-size: 1.5em;
    line-height: 1;
    margin-bottom: 1em;
  }
  h4 {
    font-size: 1.2em;
    line-height: 1.25;
    margin-bottom: 1.25em;
  }
  h5 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1.5em;
  }
  h6 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1.5em;
  }
  
  .btn-primary {
    display: inline-block;
    text-decoration: none;
    letter-spacing: var(--mainSpacing);
    color: var(--mainBlack);
    background: var(--primaryColor);
    padding: 0.4rem 0.9rem;
    border: 3px solid var(--primaryColor);
    transition: var(--mainTransition);
    text-transform: uppercase;
    cursor: pointer;
  }
  .btn-primary:hover {
    background: transparent;
    color: var(--primaryColor);
  }
  .loading {
    text-transform: capitalize;
    text-align: center;
    margin-top: 3rem;
  }
  .error {
    text-align: center;
    text-transform: uppercase;
    margin: 2rem 0;
  }
  .empty-search {
    text-align: center;
    text-transform: capitalize;
    margin: 2rem 0;
    padding: 1rem;
    letter-spacing: var(--mainSpacing);
  }
  /* end of globals */
  /* Navbar */
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem 2rem 0rem 2rem;
    background: var(--offWhite);
    z-index: 1;
  }
  .nav-header {
    display: flex;
    justify-content: space-between;
  }

  .nav-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
  .nav-icon {
    font-size: 1.5rem;
    color: var(--primaryColor);
  }
  .nav-links {
    height: 0;
    overflow: hidden;
    transition: var(--mainTransition);
    list-style-type: none;
  }
  .nav-links a {
    display: block;
    text-decoration: none;
    padding: 1rem 0;
    color: var(--mainBlack);
    transition: var(--mainTransition);
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: var(--mainSpacing);
  }
  .nav-links a:hover {
    color: var(--primaryColor);
  }
  
  .show-nav {
    height: 220px;
  }

  @media only screen and (max-width: 1030px) {
    .nav-book-now{
      margin: 0px auto;
      background: var(--primaryColor);
      display: flex;
      color: white;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
      padding: 20px 40px 20px 40px;
      font-size: 14px;
      letter-spacing: 2px;
      font-weight: bold;
      border-radius: 40px;
      margin-bottom: 2px
    }
    .nav-book-now:hover{
      background: var(--darkGreen);
    }

    .phone-icon{
      font-size: 1.5rem;
      padding-right: 0.2rem;
    }
    .nav-logo{
      height: auto;
      width: 80%
    }
    
    
  }
  @media screen and (min-width: 1030px) {
    .nav-details{
      float: right;
      display: flex;
    }
    
    .nav-btn {
      display: none;
    }
    .phone-icon{
      font-size: 2.15rem;
      padding-right: 0.2rem;
    }

    .nav-book-now:hover{
      background: var(--darkGreen);
    }

    .nav-center {
      max-width: 1170px;
      margin: 0 auto;
      display: flex;
    }

    .nav-right{
      margin-left: 10rem;
    }
    .nav-links {
      height: auto;
      display: flex;
      margin-left: 4rem;
    }
    .nav-links a {
      margin: 0 1rem;
      padding: 0.5rem 0;
    }
    .nav-book-now{
      background: var(--primaryColor);
      display: flex;
      color: white;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
      padding: 20px 40px 20px 40px;
      font-size: 16px;
      letter-spacing: 2px;
      font-weight: bold;
      border-radius: 40px;
      margin-bottom: 10px;

  }
  }
  /* end of navbar */
  /* Hero */
  .defaultHero,
  .roomsHero {
    min-height: calc(100vh - 66px);
    background: url("../../images/defaultBcg.jpeg") center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .roomsHero {
    background-image: url("../../images/destinationBcg.jpeg");
    min-height: 60vh;
  }
  /* End of Hero */
  /* Banner */
  .banner {
    display: inline-block;
    background: rgba(0, 0, 0, 0.5);
    color: var(--mainWhite);
    padding: 2rem 1rem;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .banner h1 {
    font-size: 2.5rem;
  }
  .banner div {
    width: 10rem;
    height: 5px;
    background: var(--primaryColor);
    margin: 1.7rem auto;
  }
  .banner p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 576px) {
    .banner {
      padding: 2rem 3rem;
    }
    .banner h1 {
      font-size: 3rem;
    }
  }
  @media screen and (min-width: 992px) {
    .banner {
      padding: 2rem 6rem;
    }
    .banner h1 {
      font-size: 4rem;
    }
  }
  /* End of Banner */
  /* Title */
  .section-title {
    text-align: center;
    margin-bottom: 4rem;
  }
  .section-title h4 {
    font-size: 2rem;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
  .section-title div {
    width: 5rem;
    height: 5px;
    margin: 0 auto;
    background: var(--primaryColor);
  }
  /* end of Title */
  .contact-form{
    padding: 20px 25px 20px;
    position: relative;
    body {
      font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    
    form {
      max-width: 400px;
      margin: 0 auto;
    }
    
    
    input  {
      display: block;
      box-sizing: border-box;
      width: 100%;
      border-radius: 4px;
      border: 2px solid var(--primaryColor);
      padding: 10px 15px;
      margin-bottom: 10px;
      font-size: 14px;
    }
    
    textarea {
      display: block;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      box-sizing: border-box;
      width: 100%;
      border-radius: 4px;
      border: 2px solid var(--primaryColor);
      padding: 10px 15px;
      margin-bottom: 10px;
      font-size: 14px;
      height: 8em;
    
    }
    
    
    button[type="submit"],
    input[type="submit"] {
      background: var(--primaryColor);;
      color: white;
      text-transform: uppercase;
      border: none;
      margin-top: 40px;
      padding: 20px;
      font-size: 16px;
      font-weight: 100;
      letter-spacing: 10px;
    }
    
    button[type="submit"]:hover,
    input[type="submit"]:hover {
      background: var(--darkGreen);
    }
    
    button[type="submit"]:active,
    input[type="button"]:active,
    input[type="submit"]:active {
      transition: 0.3s all;
      transform: translateY(3px);
      border: 1px solid transparent;
      opacity: 0.8;
    }
    
    label {
      line-height: 2;
      text-align: left;
      display: block;
      margin-bottom: 13px;
      margin-top: 20px;
      color: black;
      font-size: 16px;
      font-weight: 500;
    }
  }
  
  .maintenance{
    img {
      width:50%;
    }
    margin: auto 5% auto 5%;
    text-align: center;
    @media all and (max-width: 720px){
      h2 {
          font-size: 22px;
      }
      h5 {
        font-size: 16px;
      }
    }
  
  @media all and (max-width: 540px){
      h2 {
          font-size: 18px;
      }
      h5 {
        font-size: 12px;
      }
  }
}
 
  

  .contact{
    padding: 5rem 0;
    background: var(--darkGrey);
    grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  }
  .detail{
    padding: 5rem 0;
    background: var(--primaryColor);
    height: 865px
  }

  /* services */
  .services {
    padding: 5rem 0;
  }
  .services {
    background: var(--darkGrey);
    text-align: center;
  }
  .services-center {
    width: 90vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
  .service span {
    display: inline-block;
    color: var(--primaryColor);
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  .services h6 {
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
  }
  .services p {
    width: 80%;
    margin: 0 auto;
  }
  @media screen and (min-width: 992px) {
    .services-center {
      width: 95vw;
      max-width: 1170px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .services p {
      width: 100%;
    }
  }
  /*end of services */
  /* featured rooms */
  
  .featured-rooms {
    padding: 5rem 0;
  }
  .featured-rooms-center {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 40px;
  }
  @media screen and (min-width: 776px) {
    .featured-rooms-center {
      width: 90vw;
      grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    }
  }
  @media screen and (min-width: 992px) {
    .featured-rooms-center {
      width: 95vw;
      max-width: 1170px;
    }
  }
  /* end pf featured rooms */
  /* room */
  .room {
    box-shadow: var(--lightShadow);
    transition: var(--mainTransition);
  }
  .room:hover {
    box-shadow: var(--darkShadow);
  }
  
  .img-container {
    position: relative;
  }
  .img-container img {
    width: 100%;
    display: block;
    transition: var(--mainTransition);
  }
  .price-top {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    color: var(--mainWhite);
    padding: 0.3rem 0.6rem 0.5rem;
    border-bottom-right-radius: 1rem;
    font-size: 0.5rem;
    text-align: center;
    transition: var(--mainTransition);
  }
  .price-top h6 {
    margin-bottom: 0;
    font-size: 0.9rem;
    font-weight: 300;
    letter-spacing: var(--mainSpacing);
  }
  .room-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0);
    transition: all 0.3s linear;
  }
  .img-container:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  .img-container:hover img {
    opacity: 0.3;
  }
  .img-container:hover .price-top {
    opacity: 0;
  }
  .img-container:hover .room-link {
    transform: translate(-50%, -50%) scale(1);
  }
  .room-info {
    background: var(--darkGrey);
    text-transform: capitalize;
    padding: 0.5rem 0;
    text-align: center;
    font-weight: 700;
    letter-spacing: var(--mainSpacing);
  }
  /* end of room  */
  /* single room*/
  
  .single-room {
    padding: 5rem 0 0 0;
  }
  .single-room-images {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
  .single-room-images img {
    width: 100%;
    display: block;
  }
  .single-room-info {
    width: 80vw;
    display: grid;
    grid-template-columns: 1fr;
    margin: 2rem auto;
  }

  .about-info{
    width: 80vw;
    display: grid;
    grid-template-columns: 1fr;
    margin: 2rem auto;
  }
  .desc,
  .info {
    margin: 1rem 0;
  }
  .desc h3 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .desc p {
    line-height: 1.5;
  }
  .info h3,
  .info h6 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  
  .info h6 {
    font-weight: 300;
  }
  .room-extras {
    width: 80vw;
    margin: 0 auto 3rem auto;
  }
  .room-extras h6 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .extras {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
  }
  @media screen and (min-width: 992px) {
    .single-room-images,
    .single-room-info,
    .room-extras {
      width: 95vw;
      max-width: 1170px;
    }
    .single-room-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    }
    .info {
      padding-left: 3rem;
    }
  }
  /* end of single room*/
  /* roomlist */
  .roomslist {
    padding: 5rem 0;
  }
  .roomslist-center {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 30px;
  }
  
  @media screen and (min-width: 776px) {
    .roomslist-center {
      width: 90vw;
    }
  }
  @media screen and (min-width: 992px) {
    .roomslist-center {
      width: 95vw;
      max-width: 1170px;
    }
  }
  /* end of roomlist */
  /*  rooms fitler*/
  .filter-container {
    padding: 5rem 0;
  }
  .filter-form {
    width: 60vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 40px;
  }
  .form-group {
    text-transform: capitalize;
  }
  .form-group label {
    display: block;
    letter-spacing: var(--mainSpacing);
    margin-bottom: 0.5rem;
  }
  .form-control {
    width: 100%;
    background: transparent;
    font-size: 1rem;
  }
  .size-inputs {
    display: flex;
  }
  .size-input {
    width: 40%;
    padding: 0.2rem;
    border: 1px solid var(--mainBlack);
    border-radius: 0.3rem;
    margin-right: 0.3rem;
  }
  .single-extra label {
    display: inline-block;
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }
  @media screen and (min-width: 776px) {
    .filter-form {
      width: 70vw;
    }
  }
  @media screen and (min-width: 992px) {
    .filter-form {
      width: 95vw;
      max-width: 1170px;
    }
  }
  /* end of rooms fitler*/

  /* start of footer*/
  .site-footer{
    background-color:#26272b;
    padding:45px 0 20px;
    font-size:15px;
    line-height:24px;
    color:#737373;
    
    hr{
      border-top-color:#bbb;
      opacity:0.5

    }
    .container{
      p{
        text-align: center;
      } 
    }
}


.local-contact-form{
  margin-top: 1rem;
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

/* RESET RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

a {
  color: inherit;
}

input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 100%;
}

button,
label {
  cursor: pointer;
}

select {
  appearance: none;
}

/* Remove native arrow on IE */
select::-ms-expand {
  display: none;
}

/*Remove dotted outline from selected option on Firefox*/
/*https://stackoverflow.com/questions/3773430/remove-outline-from-select-box-in-ff/18853002#18853002*/
/*We use !important to override the color set for the select on line 99*/
select:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 var(--white);
}

textarea {
  resize: none;
}

ul {
  list-style: none;
}

body {
  font: 18px/1.5 "Open Sans", sans-serif;
  background: var(--primaryColor);
  color: var(--white);
  margin: 1.5rem 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1.5rem;
}


/* FORM ELEMENTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.my-form h1 {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.my-form li,
.my-form .grid > *:not(:last-child) {
  margin-bottom: 1.5rem;
}

.my-form select,
.my-form input,
.my-form textarea,
.my-form button:not(.react-datepicker__navigation):not(.react-datepicker__navigation--next) {
  width: 100%;
  line-height: 1.5;
  padding: 15px 10px;
  border: 1px solid var(--borderFormEls);
  color: var(--white);
  background: var(--bgFormEls);
  transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25),
    transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}


.my-form textarea {
  height: 170px;
}

.my-form ::placeholder {
  color: inherit;
  /*Fix opacity issue on Firefox*/
  opacity: 1;
}

.my-form select:focus,
.my-form input:focus,
.my-form textarea:focus,
.my-form button:enabled:hover,
.my-form button:focus,
.my-form input[type="checkbox"]:focus + label {
  background: var(--bgFormElsFocus);
}

.my-form select:focus,
.my-form input:focus,
.my-form textarea:focus {
  transform: scale(1.02);
}

.my-form *:required,
.my-form select {
  background-repeat: no-repeat;
  background-position: center right 12px;
  background-size: 15px 15px;
}

//.my-form *:required {
  //background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/asterisk.svg);  
//}

.my-form select {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/down.svg);
}

.my-form *:disabled {
  cursor: default;
  filter: blur(2px);
}


/* FORM BTNS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.my-form .required-msg {
  display: none;
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/asterisk.svg)
    no-repeat center left / 15px 15px;
  padding-left: 20px;
}

.my-form .btn-grid {
  position: relative;
  overflow: hidden;
  transition: filter 0.2s;
  background: #35a574
}

.my-form button {
  font-weight: bold;
}

.my-form button > * {
  display: inline-block;
  width: 100%;
  transition: transform 0.4s ease-in-out;
}

.my-form button .back {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-110%, -50%);
}

.my-form button:enabled:hover .back,
.my-form button:focus .back {
  transform: translate(-50%, -50%);
}

.my-form button:enabled:hover .front,
.my-form button:focus .front {
  transform: translateX(110%);
}



/* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (min-width: 600px) {
  .my-form .grid {
    display: grid;
    grid-gap: 1.5rem;
  }

  .my-form .grid-2 {
    grid-template-columns: 1fr 1fr;
  }

  .my-form .grid-3 {
    grid-template-columns: auto auto auto;
    align-items: center;
  }

  .my-form .grid > *:not(:last-child) {
    margin-bottom: 0;
  }

  .my-form .required-msg {
    display: block;
  }
}

@media screen and (min-width: 541px) {
  .my-form input[type="checkbox"] + label::before {
    top: 50%;
    transform: translateY(-50%);
  }

  .my-form input[type="checkbox"]:checked + label::after {
    top: 3px;
  }
}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
